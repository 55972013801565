import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'

const isString = (value) => {
  return typeof value === 'string' || value instanceof String
}

export const IndexPageTemplate = ({ banner, best_selling, offers }) => (
  <div>
    {/* <div className="w-full">
      <img
        srcSet={
          isString(banner.image)
            ? banner.image
            : banner.image.childImageSharp.fluid.srcSet
        }
        alt=""
      />
    </div> */}

    <section>
      <nav className="flex flex-wrap items-center justify-between p-6">
        <div className="flex items-center w-48 mr-6 border-b-4 border-brand">
          <span className="text-xl font-semibold tracking-tight ">
            {best_selling.heading}
          </span>
        </div>

        {best_selling.readmore && (
          <a
            href={best_selling.readmorelink || '#'}
            className="inline-block px-4 py-2 mt-4 text-sm leading-none text-white border rounded bg-brand lg:mt-0"
          >
            {best_selling.readmore}
          </a>
        )}
      </nav>
      <div className="flex flex-wrap pb-6 bg-gray-200">
        {best_selling.list.map((product) => (
          <div
            key={product.sku}
            className="flex-shrink w-1/2 mt-4 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6"
          >
            <div className="flex flex-col items-center p-2 ">
              <div className="bg-white rounded-lg">
                <a href={product.link}>
                  <img
                    srcSet={
                      isString(product.image)
                        ? product.image
                        : product.image.childImageSharp.fluid.srcSet
                    }
                    className="h-auto rounded-md shadow-2xl sm:h-56 lg:h-64"
                    alt=""
                  />
                </a>
              </div>
              <div className="-m-4 bg-white rounded-lg shadow-lg w-54 sm:w-64">
                <div className="px-5 py-2">
                  <div className="flex items-center justify-between">
                    <div className="font-light text-gray-600 text-m">
                      <p>SKU: {product.sku}</p>
                      {/* <p>Size : {product.size}</p> */}
                    </div>
                    <div className="pl-4 font-bold text-red-600 text-s sm:text-2xl">
                      ₹ {product.price}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>

    <section>
      <nav className="flex flex-wrap items-center justify-between p-3">
        <div className="items-center w-48 mr-6 ">
          <p className="text-xl font-semibold tracking-tight border-b-4 border-brand">
            {offers.heading}
          </p>
          <p className="font-semibold tracking-tight text-gray-700 text-s">
            {offers.subheading}
          </p>
        </div>

        {offers.readmore && (
          <a
            href={offers.readmorelink || '#'}
            className="inline-block px-4 py-2 mt-4 text-sm leading-none text-white border rounded bg-brand lg:mt-0"
          >
            {offers.readmore}
          </a>
        )}
      </nav>
      <div className="flex flex-wrap pb-6 bg-gray-200">
        {offers.list.map((product) => (
          <div
            key={product.sku}
            className="flex-shrink w-1/2 mt-4 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6"
          >
            <div className="flex flex-col items-center p-2 ">
              <div className="bg-white rounded-lg">
                <Link to={product.link}>
                  <img
                    srcSet={
                      isString(product.image)
                        ? product.image
                        : product.image.childImageSharp.fluid.srcSet
                    }
                    className="h-auto rounded-md shadow-2xl sm:h-56 lg:h-64"
                    alt=""
                  />
                </Link>
              </div>
              <div className="-m-4 bg-white rounded-lg shadow-lg w-54 sm:w-64">
                <div className="px-5 py-5">
                  <div className="flex items-center justify-between">
                    <div className="font-light text-gray-600 text-m">
                      <p>SKU: {product.sku}</p>
                      <p>Size : {product.size}</p>
                    </div>

                    {!product.isPriceVisible && (
                      <div className="pl-4 font-bold text-red-600 text-s sm:text-2xl">
                        ₹ {product.price}
                      </div>
                    )}

                    {product.isPriceVisible && (
                      <a href={'tel:+919944180533'}>
                        <div className="p-2 font-bold rounded text-brand text-s sm:text-2xl hover:bg-brand hover:text-white">
                          <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
                            ></path>
                          </svg>
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  banner: PropTypes.shape({
    image: PropTypes.any,
    title: PropTypes.string,
  }),
  best_selling: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
    list: PropTypes.array,
    readmore: PropTypes.string,
    readmorelink: PropTypes.string,
  }),
  offers: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
    list: PropTypes.array,
    readmore: PropTypes.string,
    readmorelink: PropTypes.string,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  console.log('frontmatter', frontmatter)
  return (
    <Layout>
      <IndexPageTemplate
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        banner={frontmatter.banner}
        best_selling={frontmatter.best_selling}
        offers={frontmatter.offers}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        heading
        subheading
        description
        banner {
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        best_selling {
          heading
          subheading
          readmore
          readmorelink
          list {
            sku
            size
            price
            link
            isPriceVisible
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        offers {
          heading
          subheading
          readmore
          readmorelink
          list {
            sku
            size
            price
            link
            isPriceVisible
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
